<template>
  <v-layout>
    <v-navigation-drawer
      width="240"
      :rail="rail"
      rail-width="60"
      app
      color="white"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <SideBarDrawer :rail="rail" />
    </v-navigation-drawer>

    <v-app-bar density="comfortable" class="py-05" color="white" elevation="0" app>
      <AppBar :rail="rail" @update:rail="updateRail" />
    </v-app-bar>

    <v-main class="main-content">
      <div class="container-fluid px-3">
        <div class="d-md-flex d-block align-items-center justify-content-between my-4 page-header-breadcrumb">
          <div>
            <p class="fw-semibold fs-18 mb-0">Vítejte zpět</p>
          </div>
        </div>
        <slot />
      </div>
      <AppFooter />
    </v-main>
    <BackToTop />
  </v-layout>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import AppFooter from "~/components/common/footer-main.vue";
import BackToTop from "~/components/common/backtotop-main.vue";
import SideBarDrawer from "@/components/common/SideBarDrawer.vue";
import AppBar from "@/components/common/AppBar.vue";

export default defineComponent({
  components: {
    SideBarDrawer,
    AppFooter,
    BackToTop,
    AppBar,
  },
  setup() {
    const rail = ref<boolean>(false);
    const isExpandOnHoverEnabled = ref<boolean>(false);

    const updateRail = () => {
      rail.value = !rail.value;
      isExpandOnHoverEnabled.value = rail.value;
    };

    const handleMouseEnter = () => {
      if (isExpandOnHoverEnabled.value && rail.value) {
        rail.value = false;
      }
    };

    const handleMouseLeave = () => {
      if (isExpandOnHoverEnabled.value && !rail.value) {
        rail.value = true;
      }
    };

    return {
      rail,
      isExpandOnHoverEnabled,
      updateRail,
      handleMouseEnter,
      handleMouseLeave,
    };
  },
});
</script>

<style lang="scss">
.main-content {
  background: #f0f1f7;
}

.header-padding {
  padding: 2px 0px;
}

.py-05 {
  padding: 2px 0px;
}
</style>
